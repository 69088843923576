<template>
	<v-card outlined>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-list three-line subheader dense>
			<v-subheader>
				<v-text-field
					solo
					flat
					:label="'crud.search' | trans"
					hide-details
					single-line
					dense
					v-model="query"
					prepend-icon="search"
				></v-text-field>
				<v-spacer></v-spacer>
				<v-btn small icon text @click="getData" color="primary" v-ripple>
					<v-icon>sync</v-icon>
				</v-btn>
			</v-subheader>
			<v-divider></v-divider>
			<template v-for="task in filterBy(scheduled_reports, query)">
				<v-list-item :key="task.id" v-if="reports.collection[task.script]" @dblclick="editTask(task)">
					<v-list-item-avatar size="60" left tile>
						<v-icon :class="`elevation-5 ${reports.collection[task.script].color} white--text`">{{
							icons[reports.collection[task.script].icon] || reports.collection[task.script].icon
						}}</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>
							<span v-if="task.params && task.params.title">
								{{ task.params.title }}
							</span>
							<span v-else>{{ task.name }}</span>
							<small v-can="'staff'" class="caption grey--text"> ({{ task.id }})</small>
						</v-list-item-title>
						<v-list-item-subtitle>
							<b> {{ "scheduled" | trans }}: </b>
							<span> {{ scheduled[task.schedule.repeat] }} </span> -
							<span v-for="(day, k) in task.schedule.days" :key="'days-' + k">
								<span v-if="weekDays[day]"> {{ ("date." + weekDays[day]) | trans }}, </span>
								<span v-else> {{ day }}, </span>
							</span>
							{{ "date.at" | trans }} {{ task.schedule.hour }}:{{ task.schedule.minute }}
						</v-list-item-subtitle>

						<v-list-item-subtitle>
							<span>{{ task.params.from | trans("date") }}</span>
							-
							<span>{{ task.params.to | trans("date") }}</span>
						</v-list-item-subtitle>

						<v-list-item-subtitle>
							<span v-for="(email, e) in task.emails" :key="'email-' + e"> {{ email }}, </span>
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action class="">
						<v-btn
							small
							v-can="'Agregar Reportes'"
							color="primary"
							depressed
							class="mt-1"
							:loading="task.__loading"
							@click="run(task)"
						>
							<v-icon small>mdi-play</v-icon>
							<span class="hidden-sm-and-down">{{ "run" | trans }}</span>
						</v-btn>
						<v-btn small color="error" depressed class="mt-1" @click="deleteTask(task)">
							<v-icon small>mdi-delete</v-icon>
							<span class="hidden-sm-and-down">{{ "crud.delete" | trans }}</span>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-divider :key="task.id + '-div'"></v-divider>
			</template>
		</v-list>
		<v-dialog v-model="editor" fullscreen persistent transition="dialog-transition">
			<v-card v-if="schedule" fill-height style="height: 100%">
				<v-toolbar color="primary" dark flat>
					<v-toolbar-title>
						{{ schedule.name }}
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-icon dark @click="editor = false">close</v-icon>
				</v-toolbar>
				<v-card-text class="ma-1" style="height: calc(100% - 120px)">
					<v-form v-model="valid">
						<v-layout row wrap>
							<v-flex class="px-1" xs12 sm6>
								<v-text-field v-model="schedule.name" :label="'name' | trans"></v-text-field>
							</v-flex>
							<v-flex class="px-1" xs12 sm6>
								<v-text-field
									v-model="schedule.schedule.emails"
									:label="'emails' | trans"
									:rules="[
										(v) =>
											(v &&
												/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,14}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/.test(
													v
												)) ||
											'Invalid e-mail.',
									]"
									hint="Separados por ;"
								></v-text-field>
							</v-flex>

							<v-flex class="px-1" xs12>
								<v-select :items="repeat" v-model="schedule.schedule.repeat" :label="'repeat' | trans"></v-select>
							</v-flex>
							<v-flex xs6 class="px-1" v-if="schedule.schedule.repeat == 'week'">
								<v-select :items="weekDaysOptions" v-model="schedule.schedule.days" label="Cada:" multiple> </v-select>
							</v-flex>
							<v-flex xs6 class="px-1" v-if="schedule.schedule.repeat == 'month'">
								<v-select :items="monthDaysOptions" v-model="schedule.schedule.days" label="Cada:" multiple> </v-select>
							</v-flex>
							<v-flex xs3 class="px-1">
								<v-select :items="hoursOptions" v-model="schedule.schedule.hour" label="A las:"> </v-select>
							</v-flex>
							<v-flex xs3 class="px-1">
								<v-select :items="minutesOptions" v-model="schedule.schedule.minute" label=":"> </v-select>
							</v-flex>
						</v-layout>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text>{{ "crud.cancel" | trans }}</v-btn>
					<v-btn :disabled="!valid" @click="saveTask(schedule)" color="primary">{{ "crud.save" | trans }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>
<script>
import Swal from "sweetalert2";

export default {
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			this.loading = true;
			this.reports = (await this.api.get("reports/list")).data;
			this.reports.collection = {};
			this.reports.forEach((r) => (this.reports.collection[r.script.toLowerCase()] = r));

			this.scheduled_reports = (await this.api.get("scheduled_reports")).data;
			this.scheduled_reports.forEach((t) => (t.script = t.script.toLowerCase()));
			this.loading = false;
		},
		async run(task) {
			this.$set(task, "__loading", true);
			try {
				var resp = await this.api.get(`scheduled_reports/${task.id}/run`);
				this.api.successful(this.$options.filters.trans("literals.completed"));
				this.$emit("run", resp.data);
			} catch (error) {
				this.api.handleError(error);
			}
			this.$set(task, "__loading", false);
		},
		async deleteTask(task) {
			var promise = await Swal.fire({
				title: `${this.$options.filters.trans("crud.delete_confirm")}`,
				type: "question",
				showConfirmButton: true,
				showCancelButton: true,
				focusCancel: true,
			});
			console.log(promise);
			if (!promise.value) {
				return;
			}
			try {
				var resp = await this.api.delete(`scheduled_reports/${task.id}`);
				this.api.successful(resp);
				this.getData();
			} catch (error) {
				this.api.handleError(error);
			}
		},
		async saveTask(task) {
			try {
				var resp = await this.api.put(`scheduled_reports/${task.id}`, {
					name: task.name,
					schedule: task.schedule,
					emails: task.schedule.emails.split(";"),
				});
				this.api.successful(resp);
				this.editor = false;
				this.schedule = null;
				this.getData();
			} catch (error) {
				this.api.handleError(error);
			}
		},
		editTask(task) {
			console.log(task);
			this.schedule = JSON.parse(JSON.stringify(task));
			this.editor = true;
		},
	},
	data() {
		return {
			editor: false,
			query: "",
			valid: false,
			schedule: null,
			loading: false,
			weekDays: {
				0: "sunday",
				1: "monday",
				2: "tuesday",
				3: "wednesday",
				4: "thursday",
				5: "friday",
				6: "saturday",
				7: "sunday",
			},
			reports: [],
			scheduled_reports: [],
			icons: {
				"fa fa-shopping-cart": "mdi-cart",
				"fa fa-cart-plus": "mdi-cart-plus",
				"fa fa-ticket": "mdi-ticket",
				"fa fa-archive": "mdi-archive",
				"fa fa-industry": "mdi-domain",
			},
			scheduled: {
				week: this.$options.filters.trans("date.weekly"),
				month: this.$options.filters.trans("date.monthly"),
			},

			// Options
			repeat: [
				{ text: this.$options.filters.trans("date.week"), value: "week" },
				{ text: this.$options.filters.trans("date.month"), value: "month" },
			],
			monthDaysOptions: [
				1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
				31,
			],
			hoursOptions: [
				"00",
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23",
			],
			weekDaysOptions: [
				{
					value: 0,
					text: this.$options.filters.trans("date.sunday"),
				},
				{
					value: 1,
					text: this.$options.filters.trans("date.monday"),
				},
				{
					value: 2,
					text: this.$options.filters.trans("date.tuesday"),
				},
				{
					value: 3,
					text: this.$options.filters.trans("date.wednesday"),
				},
				{
					value: 4,
					text: this.$options.filters.trans("date.thursday"),
				},
				{
					value: 5,
					text: this.$options.filters.trans("date.friday"),
				},
				{
					value: 6,
					text: this.$options.filters.trans("date.saturday"),
				},
			],
			minutesOptions: ["00", "15", "30", "45"],
		};
	},
};
</script>
