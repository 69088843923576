<template>
	<div class="text-center">
		<report-viewer :closable="false" v-if="report" :report="report" :task="report.scheduled_report"></report-viewer>
		<v-overlay z-index="1000" class="text-center pa-10" v-else>
			<img :src="api.url + '/img/logo.png'" class="spin" style="height: 150px" />
		</v-overlay>
	</div>
</template>
<script>
import ReportViewer from "@/components/main/ReportViewer";
export default {
	components: { ReportViewer },
	methods: {
		async getData() {
			this.$router.replace({
				path: this.$route.path,
				query: { view_mode: "report", token: this.$route.query.token },
			});
			var url = `reports/${this.reportId}?include=user,scheduledReport&append[]=configuration_text`;
			if (this.$route.query.token) {
				url += `token=${this.$route.query.token}`;
			}
			this.report = (await this.api.get(url)).data;
		},
	},
	data() {
		return {
			report: null,
		};
	},
	props: {
		reportId: {
			required: true,
		},
	},
	watch: {
		reportId: {
			immediate: true,
			handler() {
				this.report = null;
				this.getData();
			},
		},
	},
};
</script>
<style>
.spin {
	animation: spin 3s linear infinite alternate;
	border-radius: 100%;
	padding: 15px;
	opacity: 1;
}
@keyframes spin {
	25% {
		transform: rotate(15deg) scale(0.8);
	}
	50% {
		transform: rotate(0deg) scale(1.2);
	}
	75% {
		transform: rotate(-15deg) scale(0.8);
	}
}
</style>
