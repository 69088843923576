<template>
	<v-card>
		<v-toolbar color="primary darken-2" dark dense>
			<v-toolbar-title>
				{{ report.name || task.name }}
			</v-toolbar-title>
			<div class="flex-grow-1"></div>
			<small class="caption mr-4 hidden-sm-and-down">{{ report.configuration_text }}</small>
			<small class="caption hidden-sm-and-down" v-can="'staff'">ID: {{ report.id }}</small>
			<v-btn icon v-if="closable" @click="$emit('close')">
				<v-icon>close</v-icon>
			</v-btn>
		</v-toolbar>
		<v-tabs
			v-if="views.length > 1"
			dark
			v-model="tab"
			background-color="primary darken-2"
			slider-color="white"
			center-active
			centered
			fixed-tabs
			show-arrows
		>
			<v-tab v-for="view in views" :key="view.index" :href="`#tab-${view.index}`">
				{{ view.name }}
			</v-tab>
			<v-tab-item v-for="view in views" :key="view.index" :value="'tab-' + view.index">
				<iframe
					:src="`${api.url}api/reports/${report.id}/download/${view.index}?token=${api.user.token}`"
					style="width: 100%; height: 95vh"
					frameborder="0"
				></iframe>
			</v-tab-item>
		</v-tabs>
		<template v-else>
			<iframe
				:src="`${api.url}api/reports/${report.id}/download/${views[0].index}?token=${api.user.token}`"
				style="width: 100%; height: 95vh"
				frameborder="0"
			></iframe>
		</template>
		<v-speed-dial v-model="fab" bottom right fixed direction="top">
			<template v-slot:activator>
				<v-btn color="green" dark fab>
					<v-icon>mdi-cloud-download</v-icon>
				</v-btn>
			</template>
			<template v-for="file in downloadables">
				<v-btn dark small @click="downloadFile(file)" fab color="primary" v-if="file.type !== 'html'" :key="file.index">
					{{ file.type }}
				</v-btn>
			</template>
		</v-speed-dial>
	</v-card>
</template>
<script>
export default {
	data() {
		return {
			tab: 0,
			fab: false,
		};
	},
	methods: {
		downloadFile(file) {
			this.api.downloadFile(`reports/${this.report.id}/download/${file.index}`, `${file.name}.${file.type}`);
		},
	},
	props: {
		report: {
			type: Object,
			required: true,
		},
		task: {
			type: Object,
		},
		closable: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		downloadables() {
			if (!this.report) return [];
			return this.report.files.map((f, i) => {
				return {
					name: f.split("/")[1].split(".")[0],
					type: f.split("/")[1].split(".")[1],
					index: i,
				};
			});
		},
		views() {
			if (!this.report) return [];
			return this.report.files
				.map((f, i) => {
					return {
						name: f.split("/")[1].split(".")[0],
						type: f.split("/")[1].split(".")[1],
						index: i,
					};
				})
				.filter((f) => f.type == "html");
		},
	},
	watch: {
		report: {
			immediate: true,
			handler() {
				setTimeout(() => {
					if (this.views.length > 0) this.tab = `tab-${this.views[0].index}`;
				}, 100);
			},
		},
	},
};
</script>
