<template>
	<v-container fluid>
		<v-tabs>
			<v-tabs-slider></v-tabs-slider>
			<v-tab>{{ "reports" | trans }}</v-tab>
			<v-tab-item class="mt-1">
				<v-card outlined>
					<v-text-field
						solo
						:placeholder="'crud.search' | trans"
						prepend-inner-icon="search"
						@click:append-outer="getData"
						clearable
						flat
						dense
						hide-details
						single-line
						v-model="query"
					>
						<template v-slot:append-outer>
							<v-btn small icon text @click="getData" color="primary" v-ripple>
								<v-icon>sync</v-icon>
							</v-btn>
						</template>
					</v-text-field>
					<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
					<v-divider></v-divider>
					<v-list dense three-line>
						<template v-for="r in filterBy(list, query)">
							<v-list-item :key="r.id" @click="r.status == 'completed' && viewReport(r)">
								<v-list-item-avatar>
									<v-icon :class="`elevation-2 ${r.report.color} darken-1 white--text`">{{
										icons[r.report.icon] || r.report.icon
									}}</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										<span>{{ r.name || r.report.name }}</span>
										<v-chip :class="r.status" small class="ml-2">{{ r.status | trans }}</v-chip>
										<small v-can="'staff'" class="caption grey--text"> ({{ r.id }})</small>
									</v-list-item-title>
									<v-list-item-subtitle>
										<span>{{ r.from | moment("L") }}</span>
										-
										<span>{{ r.to | moment("L") }}</span>

										<span v-if="r.configuration_text"> | {{ r.configuration_text }} </span>
									</v-list-item-subtitle>
									<v-list-item-subtitle v-if="r.completed_at">
										{{ "completed at" | trans }}
										<span>{{ r.completed_at | moment("lll") }}</span>
									</v-list-item-subtitle>
									<v-list-item-subtitle v-if="r.scheduled_report_id">
										<small>
											{{ "scheduled report" | trans }}
										</small>
										<small v-can="'staff'" class="caption grey--text"> ({{ r.scheduled_report_id }}) </small>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action v-if="r.files">
									<v-btn
										v-if="r.xlsx > -1"
										color="green darken-2"
										class="mt-1"
										x-small
										dark
										@click.stop=""
										:name="r.files[r.xlsx]"
										v-download="`reports/${r.id}/download/${r.xlsx}?token=${api.user.token}`"
									>
										<v-icon left>mdi-file-excel</v-icon>
										{{ "excel" | trans }}
									</v-btn>
									<v-btn
										class="mt-1"
										v-if="r.pdf > -1"
										color="red darken-1"
										x-small
										dark
										depressed
										@click.stop=""
										:name="r.files[r.pdf]"
										v-download="`reports/${r.id}/download/${r.pdf}?token=${api.user.token}`"
									>
										<v-icon left>mdi-file-pdf</v-icon>
										{{ "pdf" | trans }}
									</v-btn>
									<v-btn
										class="mt-1"
										v-can="'SuperAdmin'"
										color="primary darken-1"
										x-small
										dark
										depressed
										@click.stop="shareLink(r)"
									>
										<v-icon left>mdi-share-variant</v-icon>
										{{ "share link" | trans }}
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider :key="r.id + '-div'"></v-divider>
						</template>
					</v-list>
				</v-card>
			</v-tab-item>
			<v-tab>{{ "scheduled reports" | trans }}</v-tab>
			<v-tab-item>
				<schedule-report-list @run="closeDialog"></schedule-report-list>
			</v-tab-item>
		</v-tabs>

		<v-dialog v-model="dialog" fullscreen persistent no-click-animation>
			<report-viewer
				v-if="report"
				:report="report"
				:task="report.report"
				@close="
					dialog = false;
					report = null;
				"
			></report-viewer>
		</v-dialog>

		<v-dialog v-model="dialogCreator" fullscreen persistent no-click-animation>
			<report-creator v-if="dialogCreator" :tasks="reports" @close="closeDialog"></report-creator>
		</v-dialog>
		<v-btn
			v-can="'Agregar Reportes'"
			@click="dialogCreator = true"
			:title="`${$options.filters.trans('crud.create')} ${$options.filters.trans('literals.report')}`"
			color="pink"
			fab
			bottom
			right
			fixed
			dark
			><v-icon>add</v-icon></v-btn
		>
		<input type="text" id="input" style="visibility: hidden" />
	</v-container>
</template>
<script>
import ReportViewer from "@/components/main/ReportViewer";
import ReportCreator from "@/components/main/ReportCreator";
import ScheduleReportList from "@/components/main/ScheduleReportList";
export default {
	components: { ReportCreator, ReportViewer, ScheduleReportList },
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			this.loading = true;
			this.reports = (await this.api.get("reports/list")).data;
			this.reports = this.reports.filter((r) => {
				return !r.module || this.api.modulos[r.module];
			});
			this.reports.collection = {};
			this.reports.forEach((r) => (this.reports.collection[r.script.toLowerCase()] = r));

			this.list = (
				await this.api.get("reports?paginate=150&order[created_at]=desc&include=user&append[]=configuration_text")
			).data.data;
			this.list.forEach((r) => {
				var script = r.script.replace("App\\Http\\s\\Reports\\", "");
				r.report = this.reports.collection[script.toLowerCase()];
				r.xlsx = r.files ? r.files.findIndex((f) => f.endsWith(".xlsx")) : null;
				r.pdf = r.files ? r.files.findIndex((f) => f.endsWith(".pdf")) : null;
			});
			this.list = this.list.filter((r) => {
				return r.report;
			});
			console.log(this.list);
			this.loading = false;
		},
		debounceGetData(time = 3500, open_last = false) {
			setTimeout(async () => {
				await this.getData();
				if (open_last && this.list.length > 0) {
					this.viewReport(this.list[0]);
				}
			}, time);
		},
		viewReport(report) {
			this.report = report;
			this.dialog = true;
		},
		shareLink(report) {
			var url = `${this.api.url}api/reports/${report.id}/view?token=${this.api.user.token}`;
			navigator.clipboard.writeText(url);
			this.api.successful(
				`${this.$options.filters.trans("link")} ${this.$options.filters.trans("crud.export.copied")}`
			);
		},
		async closeDialog(report = null) {
			this.dialogCreator = false;
			this.report = null;
			if (report) {
				await this.getData();
				var script = report.script.replace("App\\Http\\s\\Reports\\", "");
				report.report = this.reports.collection[script.toLowerCase()];
				this.viewReport(report);
			}
		},
	},
	data() {
		return {
			loading: true,
			dialog: false,
			dialogCreator: false,
			query: "",
			report: null,
			reports: [],
			list: [],
			icons: {
				"fa fa-shopping-cart": "mdi-cart",
				"fa fa-cart-plus": "mdi-cart-plus",
				"fa fa-ticket": "mdi-ticket",
				"fa fa-archive": "mdi-archive",
				"fa fa-industry": "mdi-domain",
			},
		};
	},
};
</script>
<style>
.created {
	background: #4caf50 !important;
	color: white !important;
}
.completed {
	background: #4caf50 !important;
	color: white !important;
}
.in.progress {
	background: #ffec3d !important;
	color: white !important;
}
.error {
	background: #f44336 !important;
	color: white !important;
}
</style>
