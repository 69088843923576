<template>
	<v-card fill-height>
		<v-toolbar color="primary darken-2" dark flat>
			<v-toolbar-title>
				{{ "crud.create" | trans }}
				{{ "report" | trans }}
			</v-toolbar-title>
			<div class="flex-grow-1"></div>
			<v-toolbar-items>
				<v-btn icon @click="$emit('close')">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>
		<!-- Report Form -->
		<template v-if="report">
			<!-- Report Inputs -->
			<v-card-text style="min-height: 93vh; overflow: scroll" v-if="mode == 'report'">
				<v-card-title primary-title>
					<v-layout column>
						<h5>
							<v-icon
								@click="
									report = null;
									mode = 'report';
									advanced = false;
								"
								>arrow_back</v-icon
							>
							{{ report.name }}
						</h5>
						<small class="caption">{{ report.description }}</small>
					</v-layout>
				</v-card-title>
				<!-- Fields -->
				<v-form style="height: 75vh" v-model="valid" class="my-2">
					<v-layout row wrap>
						<v-flex xs12 md6 :key="index" v-for="(input, index) in inputs">
							<default-field :props="input" v-model="data[input.key]"></default-field>
						</v-flex>
					</v-layout>

					<!-- Dates Selectors -->
					<v-layout class="mt-2" row v-if="report.dates !== false">
						<v-flex>
							<v-select
								outlined
								:items="relativeDatesOptions"
								v-model="relative_date"
								:label="'date.date range' | trans"
								@change="$emit('update:relativeDate', relative_date)"
							></v-select>
						</v-flex>
						<template v-if="relative_date == 'custom'">
							<v-flex xs3 sm4>
								<default-field
									:props="{
										type: 'date',
										rules: [(v) => !!v || 'Required'],
										text: 'date.from',
										prependIcon: 'event',
									}"
									v-model="data.from"
								></default-field>
							</v-flex>
							<v-flex xs3 sm4>
								<default-field
									:props="{
										type: 'date',
										rules: [(v) => !!v || 'Required'],
										text: 'date.to',
										prependIcon: 'event',
									}"
									v-model="data.to"
								></default-field>
							</v-flex>
						</template>
					</v-layout>

					<!-- Advanced View -->
					<v-switch :label="'advanced' | trans" v-model="advanced" color="primary"></v-switch>
					<template v-if="advanced">
						<v-text-field
							outlined
							name="title"
							:label="'title' | trans"
							v-model="data.title"
							:placeholder="report.name"
						></v-text-field>
						<v-text-field
							outlined
							name="subtitle"
							:label="'subtitle' | trans"
							v-model="data.subtitle"
							:placeholder="report.subtitle"
						></v-text-field>
					</template>
				</v-form>

				<v-card-actions>
					<v-layout row wrap>
						<v-btn :disabled="!valid" left text color="orange" @click="mode = 'schedule'"
							>{{ "schedule report" | trans }}
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn :disabled="!valid" color="primary" right tile :loading="loading" @click="createReport"
							>{{ "crud.create" | trans }} {{ "report" | trans }}</v-btn
						>
					</v-layout>
				</v-card-actions>
			</v-card-text>

			<!-- Report Scheduler -->
			<v-card-text style="min-height: 93vh; overflow: scroll" v-else class="mt-1">
				<v-btn text color="primary" @click="mode = 'report'">
					<v-icon>arrow_back</v-icon>Volver a {{ "report" | trans }}</v-btn
				>
				<v-layout row wrap>
					<v-flex class="px-1" xs12 sm6>
						<v-text-field outlined v-model="name" :label="'name' | trans"></v-text-field>
					</v-flex>
					<v-flex class="px-1" xs12 sm6>
						<v-text-field
							outlined
							v-model="schedule.emails"
							:label="'emails' | trans"
							:rules="[rules.emails]"
							hint="Separados por ;"
						></v-text-field>
					</v-flex>
					<v-flex class="px-1" xs12>
						<v-select outlined :items="repeat" v-model="schedule.repeat" :label="'repeat' | trans"></v-select>
					</v-flex>
					<v-flex xs6 class="px-1" v-if="schedule.repeat == 'week'">
						<v-select outlined :items="weekDaysOptions" v-model="schedule.days" label="Cada:" multiple> </v-select>
					</v-flex>
					<v-flex xs6 class="px-1" v-if="schedule.repeat == 'month'">
						<v-select outlined :items="monthDaysOptions" v-model="schedule.days" label="Cada:" multiple> </v-select>
					</v-flex>
					<v-flex xs3 class="px-1">
						<v-select outlined :items="hoursOptions" v-model="schedule.hour" label="A las:"> </v-select>
					</v-flex>
					<v-flex xs3 class="px-1">
						<v-select outlined :items="minutesOptions" v-model="schedule.minute" label=":"> </v-select>
					</v-flex>
					<v-flex xs12 class="text-center">
						<v-btn
							:disabled="schedule.emails.length < 4 || name.length < 2"
							@click="scheduleReport()"
							color="primary"
							:loading="loading"
							large
							>{{ "schedule report" | trans }}</v-btn
						>
					</v-flex>
				</v-layout>
			</v-card-text>
		</template>
		<!-- Report Selector -->
		<v-card-text style="min-height: 93vh; overflow: scroll" class="mt-3" v-else>
			<v-text-field
				v-model="search"
				:label="'crud.search' | trans"
				outlined
				:autofocus="$vuetify.breakpoint.mdAndUp"
				clearable
				rounded
				flat
				single-line
				@input="filter"
				hide-details
				prepend-inner-icon="search"
			></v-text-field>

			<div flat class="my-2">
				<!-- Favorites -->
				<v-layout row wrap class="pb-10 mb-10" v-if="filterBy(starredReports, search).length > 0">
					<v-flex xs12>
						<v-subheader>
							<v-avatar left color="#FFDF00" class="mx-2" size="36">
								<v-icon dark>mdi-star</v-icon>
							</v-avatar>
							<span class="subtitle-2 primary--text text--darken-2">
								{{ "starreds" | trans }}
							</span>
						</v-subheader>
					</v-flex>
					<v-flex xs12 sm6 md4 lg3 v-for="report in filterBy(starredReports, search)" :key="report.name">
						<v-card shaped class="ma-2 pa-1" hover outlined @click="select(report)">
							<v-list-item three-line>
								<v-list-item-avatar :color="report.color + ' darken-1'" size="60">
									<v-icon dark>{{ icons[report.icon] || report.icon }}</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title class="subtitle-2 mb-1"> {{ report.name }}</v-list-item-title>
									<v-list-item-subtitle v-if="report.description">{{ report.description }}</v-list-item-subtitle>
									<v-list-item-subtitle v-else>{{ "No Description" | trans }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-card-actions>
								<small style="font-size: 10px">{{ report.script }}</small>
								<v-spacer></v-spacer>
								<v-btn @click.stop="markAsStarred(report)" @mousedown.stop="" @touchstart.stop="" icon>
									<v-icon :color="starred[report.script] ? 'yellow darken-2' : ''">mdi-star</v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</v-layout>

				<template v-for="group in orderBy(groups, 'reports.length', -1)">
					<v-layout row wrap :key="group.title">
						<v-flex xs12>
							<v-subheader :key="group.title + 'header'">
								<v-avatar left color="primary darken-4" class="mx-2" size="36">
									<v-icon dark>{{ iconsPerGroup[group.title] }}</v-icon>
								</v-avatar>
								<span class="subtitle-2 primary--text text--darken-2">
									{{ group.title | trans }}
								</span>
							</v-subheader>
						</v-flex>
						<v-flex xs12 sm6 md4 lg3 v-for="report in group.reports" :key="report.name">
							<v-card shaped class="ma-2 pa-1" hover outlined @click="select(report)">
								<v-list-item three-line>
									<v-list-item-avatar :color="report.color + ' darken-1'" size="60">
										<v-icon dark>{{ icons[report.icon] || report.icon }}</v-icon>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title class="subtitle-2 mb-1"> {{ report.name }}</v-list-item-title>
										<v-list-item-subtitle v-if="report.description">{{ report.description }}</v-list-item-subtitle>
										<v-list-item-subtitle v-else>{{ "No Description" | trans }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-card-actions>
									<small style="font-size: 10px">{{ report.script }}</small>
									<v-spacer></v-spacer>
									<v-btn @click.stop="markAsStarred(report)" @mousedown.stop="" @touchstart.stop="" icon>
										<v-icon :color="starred[report.script] ? 'yellow darken-2' : ''">mdi-star</v-icon>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-flex>
					</v-layout>
				</template>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
import DefaultField from "@/components/main/DefaultField";
import moment from "moment";
export default {
	components: { DefaultField },
	async mounted() {
		this.starred = (await this.api.storage.get("starred_reports")) || {};
	},
	data() {
		return {
			rules: {
				emails: (v) =>
					(v &&
						/^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,14}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/.test(
							v
						)) ||
					"Invalid e-mail.",
			},
			starred: {},
			groups: [],
			loading: false,
			report: null,
			name: "",
			data: {
				from: moment().startOf("month").format("YYYY-MM-DD"),
				to: moment().add(1, "day").format("YYYY-MM-DD"),
			},
			schedule: {
				name: "",
				emails: "",
				days: [1, 2, 3, 4, 5],
				hour: "09",
				minute: "00",
				repeat: "week",
			},
			repeat: [
				{ text: this.$options.filters.trans("date.week"), value: "week" },
				{ text: this.$options.filters.trans("date.month"), value: "month" },
			],
			relativeDatesOptions: [
				{
					text: this.$options.filters.trans("date.yesterday"),
					value: "yesterday",
				},
				{
					text: this.$options.filters.trans("date.today"),
					value: "today",
				},
				{
					text: this.$options.filters.trans("date.yesterday and today"),
					value: "yesterday and today",
				},
				{
					text: this.$options.filters.trans("date.this week"),
					value: "this week",
				},
				{
					text: this.$options.filters.trans("date.last week"),
					value: "last week",
				},
				{
					text: this.$options.filters.trans("date.this month"),
					value: "this month",
				},
				{
					text: this.$options.filters.trans("date.last month"),
					value: "last month",
				},
				{
					text: this.$options.filters.trans("date.last 7 days"),
					value: "last 7 days",
				},
				{
					text: this.$options.filters.trans("date.last 15 days"),
					value: "last 15 days",
				},
				{
					text: this.$options.filters.trans("date.last 30 days"),
					value: "last 30 days",
				},
				{
					text: this.$options.filters.trans("date.custom"),
					value: "custom",
				},
			],
			monthDaysOptions: [
				1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
				31,
			],
			hoursOptions: [
				"00",
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23",
			],
			weekDaysOptions: [
				{
					value: 0,
					text: this.$options.filters.trans("date.sunday"),
				},
				{
					value: 1,
					text: this.$options.filters.trans("date.monday"),
				},
				{
					value: 2,
					text: this.$options.filters.trans("date.tuesday"),
				},
				{
					value: 3,
					text: this.$options.filters.trans("date.wednesday"),
				},
				{
					value: 4,
					text: this.$options.filters.trans("date.thursday"),
				},
				{
					value: 5,
					text: this.$options.filters.trans("date.friday"),
				},
				{
					value: 6,
					text: this.$options.filters.trans("date.saturday"),
				},
			],
			minutesOptions: ["00", "15", "30", "45"],
			advanced: false,
			mode: "report",
			inputs: [],
			search: "",
			valid: false,
			relative_date: "yesterday",
			iconsPerGroup: {
				users: "person",
				orders: "mdi-cart",
				invoices: "mdi-file",
				tickets: "mdi-ticket",
				annotations: "list",
				warehouses: "mdi-archive",
				workers: "mdi-account-hard-hat",
				nomina: "mdi-credit-card-outline",
			},
			icons: {
				"fa fa-shopping-cart": "mdi-cart",
				"fa fa-cart-plus": "mdi-cart-plus",
				"fa fa-ticket": "mdi-ticket",
				"fa fa-archive": "mdi-archive",
				"fa fa-industry": "mdi-domain",
			},
		};
	},
	methods: {
		async markAsStarred(report) {
			this.$set(this.starred, report.script, !this.starred[report.script]);
			await this.api.storage.set("starred_reports", this.starred);
		},
		filter() {
			var groups = {};
			var groupsArray = [];
			console.log(this.tasks);
			for (var i = 0; i < this.tasks.length; i++) {
				var report = this.tasks[i];
				if (report.active === false) continue;
				var q = this.search.toLowerCase();
				if (q) {
					if (
						report.script.toLowerCase().indexOf(q) === -1 &&
						report.name.toLowerCase().indexOf(q) === -1 &&
						report.description.toLowerCase().indexOf(q) === -1 &&
						report.group.toLowerCase().indexOf(q) === -1 &&
						this.$options.filters.trans(report.group).toLowerCase().indexOf(q) === -1
					) {
						continue;
					}
				}
				if (!groups[report.group]) {
					var group = {
						title: report.group,
						reports: [],
					};
					groups[report.group] = group;
					groupsArray.push(group);
				}
				groups[report.group].reports.push(report);
			}
			this.groups = groupsArray;
		},
		select(report) {
			this.report = report;
			this.inputs = this.report.inputs.map((i) => {
				if (i.default) this.data[i.key] = i.default;
				const item = {
					text: i.label,
					key: i.key,
					multiple: i.multiple,
					default: i.default,
					outlined: true,
					filled: true,
					shaped: true,
					type: i.type == "resource" ? (i.multiple ? "relation_multiple" : "relation") : i.type,
					options: i.options || i.items,
					attribute: i.title,
					sub_attribute: i.description,
					resource: i.resource,
					nullValue: i.nullValue,
				};
				if (item.type === "date" && item.default === "now") item.default = moment().format("YYYY-MM-DDTHH:mm:ss");
				if (item.type === "month" && item.default === "now") item.default = moment().format("YYYY-MM");
				if (item.default) this.$set(this.data, item.key, item.default);
				return item;
			});
			this.name = this.$options.filters.trans(this.report.name);
			console.log(this.inputs);
		},
		setQueryVars() {
			this.setRelativeDates();
			var data = {};
			for (const k in this.data) {
				var value = this.data[k];
				if (value !== null && value !== undefined && value !== "") {
					if (Array.isArray(value)) {
						if (value.length == 1 && value[0] instanceof File) {
							data[k] = value[0];
						} else if (value.length > 1 && value[0] instanceof File) {
							data[k] = value;
						} else {
							data[k] = `${value.join(",")}`;
						}
					} else data[k] = value;
				}
			}
			return data;
		},
		createReport() {
			var data = this.setQueryVars();
			this.loading = true;
			this.api
				.post(`reports/${this.report.script}`, data)
				.then((resp) => {
					this.loading = false;
					this.api.successful(resp);
					this.$emit("close", resp.data);
				})
				.catch((err) => {
					console.error(err);
					this.loading = false;
					this.api.handleError(err);
				});
		},
		scheduleReport() {
			this.setRelativeDates();
			var payload = {
				params: Object.assign(this.data),
				schedule: this.schedule,
				script: this.report.script,
				name: this.name,
				emails: this.schedule.emails.split(";"),
			};
			this.loading = true;
			this.api
				.post("scheduled_reports", payload)
				.then((resp) => {
					this.loading = false;
					this.api.successful(resp);
					this.$emit("close");
				})
				.catch((err) => {
					this.loading = false;
					this.api.handleError(err);
				});
		},
		updateDates() {
			this.data.from = moment(this.dates.start).startOf("day").format("YYYY-MM-DD HH:mm:ss");
			this.data.to = moment(this.dates.end).endOf("day").format("YYYY-MM-DD HH:mm:ss");
		},
		setRelativeDates() {
			switch (this.relative_date) {
				case "today":
					this.data.from = "today";
					this.data.to = "tomorrow";
					break;
				case "yesterday":
					this.data.from = "yesterday";
					this.data.to = "today";
					break;
				case "yesterday and today":
					this.data.from = "yesterday";
					this.data.to = "tomorrow";
					break;
				case "this week":
					this.data.from = "monday, this week";
					this.data.to = "sunday, this week";
					break;
				case "last week":
					this.data.from = "monday, last week";
					this.data.to = "sunday, last week";
					break;

				case "this month":
					this.data.from = "first day of this month 00:00:00";
					this.data.to = "last day of this month 23:59:59";
					break;
				case "last month":
					this.data.from = "first day of last month 00:00:00";
					this.data.to = "last day of last month 23:59:59";
					break;
				case "last 7 days":
					this.data.from = "today -7days";
					this.data.to = "today";
					break;
				case "last 15 days":
					this.data.from = "today -15days";
					this.data.to = "today";
					break;
				case "last 30 days":
					this.data.from = "today -30days";
					this.data.to = "today";
					break;
				default:
					break;
			}
		},
	},
	computed: {
		starredReports() {
			return this.tasks.filter((r) => {
				return this.starred[r.script] === true;
			});
		},
	},
	props: {
		tasks: {
			type: Array,
			required: true,
		},
	},
	watch: {
		tasks: {
			immediate: true,
			handler() {
				this.filter();
			},
		},
	},
};
</script>
